export function isAssetVideo(filename?: string): boolean {
  return !!filename && (filename.endsWith('.mp4') || filename.endsWith('.webm'))
}

export function getAssetMimeType(filename?: string): string | undefined {
  if (filename?.endsWith('.mp4')) return 'video/mp4'
  if (filename?.endsWith('.webm')) return 'video/webm'

  return undefined
}
